import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MotherCoin from './pages/MotherCoin';
 
const router = createBrowserRouter([
  {
    path: "/",
    element: <MotherCoin />,
  },
]); 
 


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <RouterProvider router={router} /> 
); 
reportWebVitals();
